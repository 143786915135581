import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import 'remixicon/fonts/remixicon.css'
import "swiper/css";
import "swiper/css/navigation";
import 'swiper/swiper-bundle.css';
import 'swiper/css/pagination';
import 'react-loading-skeleton/dist/skeleton.css'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Container/Home';
import ProductListing from './Container/ProductListing';
import ProductDetail from './Container/ProductDetail';
import Profile from './Container/Account/profile';
import Orders from './Container/Account/order';
import OrderDetails from './Container/Account/order_detail';
import Address from './Container/Account/address';
import Account from './Container/Account/account';
import Pages from './Container/Pages';
import PageNotFound from './Container/Pages/page_not_found';
import Login from './Container/Login';
import Register from './Container/Register';
import Cart from './Container/Cart/cart';
import Wishlist from './Container/Account/wishlist';
import BookAppointment from './Container/BookAppointment';
import TermsCondition from './Container/TermsCondition';
import ContactUs from './Container/Contactus';
import AboutUs from './Container/AboutUs';
import VerifyEmail from './Container/VerifyEmail';
import ResetPassword from './Container/ResetPassword';
import TradeLogin from './Container/TradeLogin';
import BlogDetail from './Container/BlogDetail';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import SearchResult from './Container/SearchResult';
import FAQ from './Container/Faq';
import TradeSignIn from './Container/TradeSignIn';
import TradeRegister from './Container/TradeRegister';
import VerifyTradeEmail from './Container/VerifyTradeEmail';
import ResetTradePassword from './Container/ResetTradePassword';
import BeSpoke from './Container/Pages/BeSpoke';
import Blog from './Container/Blog';
import OrderStatus from './Container/OrderStatus/order_status';
import Unsubscribe from '../src/Container/Unsubscribe/index'
import Checkout from './Container/Checkout/index';
import CheckoutAddress from './Container/Checkout/address_section';
import Thankyou from './Container/Checkout/thankyou';


function App() {
  return (
    <>
    <ToastContainer
          position="bottom-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          draggable
          theme="light"
        />
      <Router>
      <Routes>
        <Route path="/" activeClassName="active" element={<Home />} />
        {/* <Route path="/product-list" activeClassName="active" element={<ProductListing />} /> */}
        {/* <Route path="/:type/:slug" activeClassName="active" element={<ProductListing />} /> */}
        <Route path="/category/:slug" activeClassName="active" element={<ProductListing />} />
        <Route path="/tag/:slug" activeClassName="active" element={<ProductListing />} />
        <Route path="/shop-all" activeClassName="active" element={<ProductListing />} />
        <Route path="/product/:slug" activeClassName="active" element={<ProductDetail />} />
        <Route path="/unsubscribe" activeClassName="active" element={<Unsubscribe />} />
        <Route path="/profile" activeClassName="active" element={<Profile />} />
        <Route path="/profile/:slug" activeClassName="active" element={<Profile />} />
        <Route path="/book-an-appointment" activeClassName="active" element={<BookAppointment />} />
        <Route path="/login" activeClassName="active" element={<Login />} />
        <Route path="/cart/address" activeClassName="active" element={<CheckoutAddress />} />
        <Route path="/trade-sign-in" activeClassName="active" element={<TradeSignIn />} />
        <Route path="/trade-register" activeClassName="active" element={<TradeRegister />} />
        <Route path="/verify-email" activeClassName="active" element={<VerifyEmail />} />
        <Route path="/verify-trade-account-email" activeClassName="active" element={<VerifyTradeEmail />} />
        <Route path="/change-password" activeClassName="active" element={<ResetPassword />} />
        <Route path="/change-trade-account-password" activeClassName="active" element={<ResetTradePassword />} />
        <Route path="/trade-program" activeClassName="active" element={<TradeLogin />} />
        <Route path="/cart" activeClassName="active" element={<Cart />} />
        <Route path="/blog/:slug" activeClassName="active" element={<BlogDetail />} />
        <Route path="/blog" activeClassName="active" element={<Blog />} />
        <Route path="/wishlist" activeClassName="active" element={<Wishlist />} />
        {/* <Route path="/bespoke" activeClassName="active" element={<BeSpoke />} /> */}
        <Route path="/checkout" activeClassName="active" element={<Checkout />} />
        <Route path="/thankyou/:id"  activeClassName="active" element={<Thankyou/>} />
        <Route path="/register" activeClassName="active" element={<Register />} />
        <Route path="/orders" activeClassName="active" element={<Orders />} />
        <Route path="/faq" activeClassName="active" element={<FAQ />} />
        <Route path="/order/:id" activeClassName="active" element={<OrderDetails />} />
        <Route path="/order-status" activeClassName="active" element={<OrderStatus />} />
        <Route path="/address" activeClassName="active" element={< Address/>} />
        <Route path="/address/:id" activeClassName="active" element={< Address/>} />
        <Route path="/account" activeClassName="active" element={< Account/>} />
        <Route path="/contact-us" activeClassName="active" element={< ContactUs/>} />
        <Route path="/about-uss" activeClassName="active" element={< AboutUs/>} />
        <Route path="/search-result/:slug" activeClassName="active" element={<SearchResult />} />
        <Route path="/terms-condition" activeClassName="active" element={< TermsCondition/>} />
        <Route path="/:slug" activeClassName="active" element={< Pages/>} />
        <Route path="/error_404" activeClassName="active" element={< PageNotFound/>} />
      </Routes>
    </Router>
    
    
    
    </>
  
  );
}

export default App;
