
import Header from '../../Components/Header/index'
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Footer from '../../Components/Footer/index'
import Container from "react-bootstrap/Container";
import { Col, Image, Row } from "react-bootstrap";
import Accountsidebar from './account_sidebar';
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { ApiService } from '../../Components/Services/apiservices';
import multiCurrency from '../../Components/Elements/multiCurrency';


const OrderDetails = () => {


    const didMountRef = useRef(true);
  const { id } = useParams()
  const [orderDetail, setOrderDetail] = useState({});
  const [itemsData, setItemsData] = useState([]);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [transId, setTransId] = useState(0);
  const [loading, setLoading] = useState(true);

  const navigate = useState()
  const handleOpenModal = (status, transid) => {
    setTransId(transid);
    setShowCancelModal(status);
  };

  const handleCancelModal = () => {
    setShowCancelModal(false);
  };

  useEffect(() => {
    if (didMountRef.current) {
      getOrderData();
    }
    didMountRef.current = false;
  }, []);

  const getOrderData = () => {
    const dataString = {
      trans_id: id,
    };
    ApiService.postData("get-order-detail", dataString).then((res) => {
      if (res.status === "success") {
        console.log(res)
        setOrderDetail(res.row_orders_data);
        setItemsData(res.row_orders_data.items);
      } else if (res.message == "Session expired") {
        localStorage.removeItem("USER_TOKEN");
        navigate("/");
        setLoading(false);
      }
      setLoading(false);
    });
  };


    return (<>

        <Header></Header>
        <div className="subheader ">
            <Container>
                <Row>
                    <Col lg={12}>
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                            <Breadcrumb.Item href="/account">My Account</Breadcrumb.Item>
                            <Breadcrumb.Item href="/account/my-order">My Orders</Breadcrumb.Item>
                            <Breadcrumb.Item active>Order Details</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
            </Container>

        </div>
        <section className="section-gap-medium pt-0">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3">
                        <Accountsidebar />
                    </div>
                    <div className="col-lg-9">
                        <div className="section-title mb-20">
                            <h2>Order Details</h2>
                        </div>
                        {orderDetail && orderDetail.trans_id > 0 ? (
                        <>
                            <div className="order_bar d-flex justify-content-between position-relative">
                                <div className='bar_list'>
                                    <span className='d-flex align-items-center justify-content-center mb-2 active'>1</span>
                                    <h6 className='fw-semibold mb-1'>Order Conformed</h6>
                                    <p className='mb-0'>{orderDetail.trans_datetime}</p>
                                </div>
                                <div className='bar_list text-center'>
                                    <span className='d-flex align-items-center justify-content-center mb-2 m-auto'>2</span>
                                    {orderDetail.trans_status && (orderDetail.trans_status == 2 || orderDetail.trans_status == 3) ?
                                        (<>
                                        <h6 className='fw-semibold mb-1'>Shipped</h6>
                                        <p className='mb-0'><a href={orderDetail.trans_tracking_url} target='_blank'>Check Status here</a></p>
                                        <p className='mb-0'>Tracking ID: {orderDetail.trans_tracking_id}</p>

                                        </>):(<>
                                            <h6 className='fw-semibold mb-1'>Shipping Progress</h6>
                                        </>)
                                    }
                                    
                                </div>
                                <div className='bar_list text-right'>
                                
                                    {orderDetail.trans_status && orderDetail.trans_status == 3 ?
                                        (<>
                                            <span className='d-flex align-items-center justify-content-center mb-2 active me-0 ms-auto'>3</span>
                                            <h6 className='fw-semibold mb-1'>Delivered</h6>
                                            <p className='mb-0'>Delivered on {orderDetail.trans_delivered_date}</p>
                                        </>):(<>
                                            <span className='d-flex align-items-center justify-content-center mb-2 me-0 ms-auto'>3</span>
                                            <h6 className='fw-semibold mb-1'>To Deliver</h6>

                                        </>)
                                    }

                                    
                                    
                                </div>
                            </div>
                            <div className='delivery_info'>
                                <h5 className='fw-semibold'>Delivery information</h5>
                                <ul className='ps-0 mb-0'>
                                    <li>
                                        <div className='info_row d-flex align-items-center'>
                                            <span>Name</span>
                                            <p className='mb-0'>{orderDetail.trans_user_name}</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='info_row d-flex align-items-center'>
                                            <span>Address</span>
                                            <p className='mb-0'>{orderDetail.trans_delivery_address}</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='info_row d-flex align-items-center'>
                                            <span>Contact number</span>
                                            <p className='mb-0'>{orderDetail.trans_user_mobile}</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='info_row d-flex align-items-center'>
                                            <span>Order Status</span>
                                            <p className='mb-0'>{orderDetail.orderstatus}</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="item_order">
                                <h5 className='head'>Item ordered</h5>
                                {orderDetail.items.map((subchild, index) => {
                                return (<div className='order_list d-flex justify-content-between'>
                                    <div className='d-flex'>
                                        <div className='order_list_img'>
                                            <Image src={subchild.td_item_image} />
                                        </div>
                                        <div className='order_list_txt ms-3'>
                                            <p className='mb-0 fw-semibold'>{subchild.td_item_title}</p>
                                            <span>{subchild.td_item_qty} x {multiCurrency(subchild.td_item_sellling_price)}</span>
                                        </div>
                                    </div>
                                    <h5 className='mb-0 md-ms-4 ms-3'>{multiCurrency(subchild.td_item_qty * subchild.td_item_sellling_price)}</h5>
                                </div>)
                                })}
                                
                            </div>

                            
                            <div className='sub_total ms-auto me-0 mt-4'>
                                <div className='sub_total_list d-flex align-items-center justify-content-between '>
                                    <p className='mb-0'>Sub Total</p>
                                    <span>{multiCurrency(orderDetail.item_sub_total)}</span>
                                </div>
                                <div className='sub_total_list discount d-flex align-items-center justify-content-between '>
                                    <p className='mb-0'>Discounts</p>
                                    <span>- {multiCurrency(orderDetail.trans_discount_amount)}</span>
                                </div>
                                <div className='sub_total_list discount d-flex align-items-center justify-content-between '>
                                    <p className='mb-0'>Coupon Discount</p>
                                    <span>- {multiCurrency(orderDetail.trans_coupon_dis_amt)}</span>
                                </div>
                                <div className='sub_total_list d-flex align-items-center justify-content-between '>
                                    <p className='mb-0'>Delivery Charges</p>
                                    <span>{multiCurrency(orderDetail.trans_shipping_amount)}</span>
                                </div>
                                <div className='sub_total_list total d-flex align-items-center justify-content-between '>
                                    <p className='mb-0'><b>Total</b></p>
                                    <span>{multiCurrency(orderDetail.trans_amt)}</span>
                                </div>
                            </div>
                        </>

                            ) : (
                        !loading && (
                            <div>
                            <h4>No Orders Found!</h4>
                            <p><a class="btn btn-underline-primary p-0" href="/" >Shop Now <i class="ri-arrow-right-line"></i></a></p>
                            </div>
                        )
                        )}

                    </div>
                </div>
            </div>
        </section>
        <Footer></Footer>



    </>)
}

export default OrderDetails