
import Header from '../../Components/Header/index'
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Footer from '../../Components/Footer/index'
import Container from "react-bootstrap/Container";
import { Col, Image, Row } from "react-bootstrap";
import Accountsidebar from './account_sidebar';
import { useContext, useState, useEffect, useRef } from 'react';
import DataContext from '../../Components/Elements/context';
import { ApiService } from '../../Components/Services/apiservices';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import AddressModal from '../../Components/Modals/address_modal';


const Address = () => {
    
    const contextValues = useContext(DataContext)
    const [isLoading, setisLoading] = useState(false)
    const [userAddressDetails, setUserAddressDetails] = useState({
        ua_id: 0,
        ua_fname: "",
        ua_lname: "",
        ua_email: "",
        ua_mobile: "",
        ua_pincode: "",
        ua_state_name: "",
        ua_city_name: "",
        ua_apartment: "",
        ua_default_address: "",
        ua_complete_address: '',
        ua_country_id: '',
        ua_address_type: "Home",
        ua_address_type_other: "",
    });
    const didMountRef = useRef(true);
    const navigate = useNavigate()
    const [userAddressList, setuserAddressList] = useState([])
    useEffect(() => {
        if (didMountRef.current) {
            getuserAddress()
        }
        didMountRef.current = false;
    }, []);
    const getuserAddress = () => {
        setisLoading(true)
        ApiService.fetchData('get-user-address').then((res) => {
            if (res.status == 'success') {
                setuserAddressList(res.resUserAddress)
                setisLoading(false)
            }
            else if (res.status == 'error' && res.message == 'Session expired') {
                setisLoading(false)
                localStorage.removeItem('USER_TOKEN')
                navigate('/')
            }
        }).catch(() => {
            setisLoading(false)
        })

    }

    const deleteaddress = (value) => {
        if (window.confirm("Are you sure about delete the address ?")) {
            const dataString = {
                addrid: value,
            };
            ApiService.postData("removeAddress", dataString).then((res) => {
                if (res.status == "success") {
                    getuserAddress();
                }
                else {
                    toast.error(res?.message)
                }
            });
        } else {

        }
    };
    const navigateback = (e) => {
        e.preventDefault()

        if (window.history.length > 2) {
            navigate(-1);
        } else {
            navigate('/');
        }
    };

    const saveAddress = () =>{
        var ua_fname = document.getElementById("ua_fname");
        if(ua_fname == ""){
            return false;
        }
    }
    const addressModal = () => {
        setUserAddressDetails({
            ua_id: 0,
            ua_fname: "",
            ua_lname: "",
            ua_email: "",
            ua_mobile: "",
            ua_pincode: "",
            ua_state_name: "",
            ua_city_name: "",
            ua_apartment: "",
            ua_default_address: "",
            ua_complete_address: '',
            ua_country_id: '',
            ua_address_type: "Home",
            ua_address_type_other: "",
            ua_state_id:'',
            ua_city_id:''
        })

        setTimeout(() => {
            contextValues.setToggleAddressModal(!contextValues.toggleAddressModal)
        }, 500)

    }
    const editAddress = (value) => {
        setUserAddressDetails({
            ua_id: value.ua_id,
            ua_fname: value.ua_fname,
            ua_lname: value.ua_lname,
            ua_complete_address: value.ua_complete_address,
            ua_email: value.ua_email,
            ua_mobile: value.ua_mobile,
            ua_pincode: value.ua_pincode,
            ua_house_no: value.ua_house_no,
            ua_apartment: value.ua_apartment,
            ua_state_name: value.ua_state_name,
            ua_city_name: value.ua_city_name,
            ua_default_address: value.ua_default_address,
            ua_country_id: value.ua_country_id,
            ua_address_type: value.ua_address_type,
            ua_address_type_other: value.ua_address_type_other,
             ua_state_id:value.ua_state_id,
            ua_city_id:value.ua_city_id
        })
        contextValues.setToggleAddressModal(!contextValues.toggleAddressModal)
        
    }


    return (<>
        <Header></Header>
        <div className="subheader ">
            <Container>
                <Row>
                    <Col lg={12}>
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                            <Breadcrumb.Item href="/account">My Account</Breadcrumb.Item>
                            <Breadcrumb.Item active>Address Book</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
            </Container>
        </div>
        <section className="section-gap-medium pt-0">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3">
                        <Accountsidebar></Accountsidebar>
                    </div>
                    <div className="col-lg-9">
                        <div className="section-title mb-20 d-flex align-items-center justify-content-between">
                            <h2>Default Addressess</h2>
                            <a href='#' onClick={() => { addressModal() }}>Add Address</a>
                        </div>
                        <div className='address_item'>
                            
                                {userAddressList.map((address, index) => (

                                    <div className='address_list d-flex'>
                                        <div className='address_list_text  ms-3'>
                                            <h5 className='fw-semibold'>{address.ua_fname} {address.ua_lname}</h5>
                                            <h6>{address.ua_mobile}</h6>
                                            <p className='mb-3'>{address.ua_apartment}, {address.ua_city_name}, {address.ua_state_name}, {address.ua_pincode}</p>
                                            <div className='d-flex align-items-center'>
                                                <a href='#' onClick={() => { editAddress(address) }} >Edit Address</a>
                                                <a href='#' onClick={() => { deleteaddress(address.ua_id) }} className='ms-2'>Delete Address</a>
                                            </div>
                                        </div>

                                    </div>
                                ))}
                                
                            
                        </div>

                    </div>
                </div>
            </div>
        </section>
        <Footer></Footer>

        {contextValues?.toggleAddressModal && <AddressModal editAddDetails={userAddressDetails}></AddressModal>}

    </>)
}

export default Address