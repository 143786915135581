
import Header from '../../Components/Header/index'
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Footer from '../../Components/Footer/index'
import Container from "react-bootstrap/Container";
import { Col, Image, Row } from "react-bootstrap";
import { Link } from 'react-router-dom';
import Accountsidebar from './account_sidebar';
// import AccountSidebar from "./account_sidebar";
import React, { useContext, useRef, useState, useEffect } from "react";
import { ApiService } from '../../Components/Services/apiservices';
import multiCurrency from '../../Components/Elements/multiCurrency';

const Orders = () => {

  const didMountRef = useRef(true);
  const [orderData, setOrderData] = useState([]);
  const [spinnerLoading, setSpinnerLoading] = useState(false);

  useEffect(() => {
    if (didMountRef.current) {

      getorderData();

    }
    didMountRef.current = false;
  }, []);

  const getorderData = () => {
    ApiService.fetchData("get-order-data").then((res) => {
      if (res.status == "success") {
        setOrderData(res.orderList);
        setSpinnerLoading(false);
      } else {
        setSpinnerLoading(false);
      }
    });


  };


    return (<>

        <Header></Header>
        <div className="subheader ">
            <Container>
                <Row>
                    <Col lg={12}>
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                            <Breadcrumb.Item href="/account">My Account</Breadcrumb.Item>
                            <Breadcrumb.Item active>My Orders</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
            </Container>

        </div>
        <section className="section-gap-medium order_p pt-0">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3">
                        <Accountsidebar />
                    </div>
                    <div className="col-lg-9">
                        <div className="section-title mb-20">
                            <h2>My Orders</h2>
                        </div>

                        {!spinnerLoading ? (
                  orderData.length > 0 ? (
                    <div className="row ">
                      {orderData.map((value, index) => (
                        <div className="order_item">
                            <div className='order_head d-flex align-items-center justify-content-between'>
                                <h6 className='fw-semibold mb-0'>Order ID: # {value.trans_order_number}</h6>
                                <span className='in_delivery fw-semibold'>
                                {value.trans_status === 0 && (
                                    <>Processing</>
                                  )}
                                   {value.trans_status === 1 && (
                                    <>Confirmed</>
                                  )}
                                  {value.trans_status === 2 && (
                                    <>Shipped</>
                                  )}
                                  
                                  {value.trans_status === 3 && (
                                    <>Delivered</>
                                  )}
                                  {value.trans_status === 4 && (
                                    <>Cancelled</>
                                  )}
                                  {value.trans_status === 5 && (
                                    <>On Hold</>
                                  )}
                                </span>
                            </div>
                            <div className='order_body'>

                            {value.items.map((subchild, index) => {
                                return (
                                    <div className='order_list d-flex justify-content-between'>
                                        <div className='d-flex'>
                                            <Link to='/order/{value.trans_order_number}'>
                                                <div className='order_list_img'>
                                                {subchild.td_item_image && 
                                                    <Image src={subchild.td_item_image?subchild.td_item_image:""} alt={subchild.td_item_title} />
                                                }
                                                </div>
                                            </Link>
                                            <div className='order_list_txt ms-3'>
                                                <p className='mb-0 fw-semibold'><Link to={'/order/' + value.trans_order_number}>{subchild.td_item_title}</Link></p>
                                                <span>{subchild.td_item_qty} x ₹{subchild.td_item_sellling_price}</span>
                                            </div>
                                        </div>
                                        <h5 className='mb-0 md-ms-4 ms-3'>{multiCurrency(subchild.td_item_qty*subchild.td_item_sellling_price)}</h5>
                                    </div>
                                );
                              })}

                            </div>
                            <div className='order_foot d-flex justify-content-end'>
                                <h5 className='mb-0'>₹{value.trans_amt}</h5>
                            </div>

                        </div>
                      ))}
                      </div>
                    ) : (
                    <div>
                      <h4>No Orders Found!</h4>
                      <p><a class="btn btn-underline-primary p-0" href="/" >Shop Now <i class="ri-arrow-right-line"></i></a></p>
                    </div>
                  )
                ) : null}
                    </div>
                </div>
            </div>
        </section>
        <Footer></Footer>




    </>)
}

export default Orders